<template>
  <div class="mb-5 grid grid-cols-1 py-2 md:mb-6 md:grid-flow-row 2xl:mx-64">
    <div class="row-start-1 flex items-center gap-x-2">
      <inline-svg src="/img/icons/ai-request.svg" class="stroke-ds-button-icon" />
      <h2 class="dashboard-title text-ds-text-primary">Tweet variations</h2>
    </div>
    <new-button size="sm" type="secondary" inactive class="row-start-3 md:row-start-1 md:ml-auto">
      <inline-svg src="/img/icons/ai-request.svg" class="mr-1 stroke-ds-button-primary-hover" />
      <span class="text-ds-button-primary-hover"
        ><span class="text-ds-button-primary-hover">Regenerate</span></span
      ></new-button
    >
    <div
      class="row-start-2 mt-5 grid grid-cols-1 gap-y-4 md:col-span-2 md:mt-6 md:flex md:flex-row md:gap-y-0"
    >
      <div class="w-full md:w-1/3">
        <tweet-variation-card
          class="relative h-full bg-ds-foreground"
          size="lg"
          :isCollapsable="true"
        >
          <div
            class="absolute right-2 top-2 flex items-center gap-x-2 rounded-2xl bg-ds-button-secondary-background px-2 py-1 text-ds-text-primary"
          >
            <div class="h-2 w-2 rounded-full bg-green-500 text-xs"></div>
            Motivational
          </div>
          <div class="flex h-full flex-col gap-y-5">
            <div class="card-avatar-title mb-2 flex items-center gap-x-2 gap-y-2">
              <img
                src="https://pbs.twimg.com/profile_images/1337607516008501250/6Ggc4S5n_400x400.png"
                class="avatar-img-sm"
              />
              <div class="gap-y flex min-w-0 flex-wrap gap-x-1 text-base">
                <p class="truncate text-ellipsis max-w-full font-bold text-primary">Tesla</p>
                <p class="truncate max-w-full font-semi-medium text-ds-text-secondary-tertiary">
                  @Tesla
                </p>
              </div>
            </div>
            <p class="text-ds-text-primary">
              You can control most of your Tesla’s features via voice commands (with the exception
              of driving-related commands) No need to use specific words or phrases—simply speak the
              way you normally would & our natural language processor will translate your request to
              an action for your car
            </p>
          </div>
          <template #footer>
            <div class="mt-auto flex items-center justify-between">
              <p class="text-sm text-ds-text-secondary">8:36 AM . Apr 3, 2023</p>
              <!-- <div class="flex gap-x-1">
                <new-button size="sm" type="transparent" icon inactive>
                  <inline-svg
                    src="/img/icons/arrow-down.svg"
                    class="rotate-180 transform rounded-full bg-ds-button-secondary-background fill-main-color-100"
                /></new-button>
                <new-button size="sm" type="transparent" icon>
                  <inline-svg
                    src="/img/icons/arrow-down.svg"
                    class="rounded-full bg-ds-button-secondary-background fill-main-color-100"
                /></new-button>
              </div> -->
            </div>
          </template>
        </tweet-variation-card>
      </div>
      <div
        class="horizontal-scrollable-container row-span-3 flex w-full gap-x-4 overflow-x-auto pb-16 pt-8 md:w-2/3 md:pl-5"
      >
        <tweet-variation-card v-for="index in 6" :key="index" class="flex-none bg-ds-background">
          <div class="mb-2 flex items-center gap-2">
            <img
              src="https://pbs.twimg.com/profile_images/1337607516008501250/6Ggc4S5n_400x400.png"
              class="avatar-img-sm"
            />
            <div class="gap-y flex min-w-0 flex-wrap gap-x-1 text-base">
              <p class="truncate text-ellipsis max-w-full font-bold text-primary">Tesla</p>
              <p class="truncate max-w-full font-semi-medium text-ds-text-secondary-tertiary">
                @Tesla
              </p>
            </div>
          </div>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellat asperiores dolorum
            quasi accusantium nulla ipsum? Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Earum ipsum recusandae voluptates soluta repudiandae ducimus sint cupiditate
            dignissimos, aliquam rerum.
          </p>
          <template #action>
            <div class="flex w-full items-center justify-between py-2">
              <new-button size="sm">Approve</new-button>
              <div class="flex">
                <new-button size="sm" type="transparent" icon>
                  <inline-svg src="/img/icons/write-icon.svg" class="h-5 w-5 stroke-main-color-100"
                /></new-button>
                <new-button size="sm" type="transparent" icon>
                  <inline-svg src="/img/icons/trash-icon.svg" class="fill-main-color-100"
                /></new-button>
              </div>
            </div>
          </template>
        </tweet-variation-card>
      </div>
    </div>
  </div>
</template>
<script>
  import TweetVariationCard from '../Cards/DashboardCards/TweetVariationCard.vue';
  export default {
    name: 'TweetVariations',

    components: {
      TweetVariationCard,
    },
    data() {
      return {
        someArray: [1, 2, 3, 4, 5],
      };
    },
    methods: {
      nextTweet() {
        const tweet = this.someArray.shift();
        setTimeout(() => {
          this.someArray.push(tweet);
        }, 250);
      },
      previousTweet() {
        const tweet = this.someArray.pop();
        setTimeout(() => {
          this.someArray.push(tweet);
        }, 250);
      },
    },
  };
</script>
<style lang="scss" scoped>
  h2.dashboard-title text-ds-text-primary {
    @apply text-xl font-bold;
  }
</style>
