<template>
  <div class="2xl:mx-64">
    <div class="mb-5 flex items-center gap-x-2 md:mb-0">
      <inline-svg src="/img/icons/new/trending.svg" class="stroke-ds-button-icon" />
      <h2 class="dashboard-title text-ds-text-primary">Trending Tweet</h2>
    </div>
    <div class="flex flex-col-reverse md:flex-row">
      <div class="-mt-2 w-full px-3 md:my-2 md:-mt-0 md:w-2/3 md:px-0">
        <dashboard-base-card
          class="border flex h-full flex-col items-center justify-center space-y-10 rounded-lg border-gray-60 p-10 text-lg md:px-40 md:py-16"
        >
          <div class="flex items-center space-x-2 fill-ds-text-primary">
            <inline-svg src="/img/icons/socials/linkedin-icon.svg" />
            <inline-svg src="/img/icons/socials/tiktok-icon.svg" />
            <inline-svg src="/img/icons/socials/shorts-icon.svg" />
            <inline-svg src="/img/icons/socials/instagram-icon.svg" />
            <inline-svg src="/img/icons/socials/facebook-icon.svg" />
          </div>
          <p class="text-center text-sm text-ds-text-secondary">
            This content is getting traction, let’s promote it on your other platforms when it
            reaches:
          </p>
          <div class="flex w-full flex-col items-center space-y-6">
            <div class="flex items-center gap-x-2">
              <inline-svg src="/img/icons/new/heart.svg" class="stroke-ds-text-primary" />
              <p class="text-sm font-bold text-ds-text-primary">
                {{ promoteTweetThreshold }} likes
              </p>
            </div>
            <div class="w-64">
              <vue-slider
                :interval="10"
                v-model="promoteTweetThreshold"
                :dotStyle="{
                  backgroundColor: '#0d70e2',
                  borderColor: '#17191D',
                  dropShadow: '4 4 10px black',
                }"
                :dotSize="24"
                :dotOptions="DotOption"
                :railStyle="{ backgroundColor: '#4C5160', height: '6px' }"
                :processStyle="{ backgroundColor: '#0d70e2', height: '6px' }"
                :min="0"
                :max="100"
                :lazy="true"
              ></vue-slider>
            </div>
            <new-button size="xs">Approve</new-button>
          </div>
        </dashboard-base-card>
      </div>
      <div class="relative w-full md:w-1/3">
        <div class="absolute right-0 -mt-16 text-6xl md:left-0">🔥</div>
        <tweet-variation-card
          class="relative h-full bg-ds-foreground md:-ml-2"
          size="lg"
          :isCollapsable="true"
        >
          <div
            class="absolute right-2 top-2 flex items-center gap-x-2 rounded-2xl bg-ds-button-secondary-background px-2 py-1 text-ds-text-primary"
          >
            <div class="h-2 w-2 rounded-full bg-green-500 text-xs"></div>
            Motivational
          </div>
          <div class="flex h-full flex-col gap-5">
            <div class="card-avatar-title mb-2 flex items-center gap-x-2 gap-y-2">
              <img
                src="https://pbs.twimg.com/profile_images/1337607516008501250/6Ggc4S5n_400x400.png"
                class="avatar-img-sm"
              />
              <div class="gap-y flex min-w-0 flex-wrap gap-x-1 text-base">
                <p class="truncate text-ellipsis max-w-full font-bold text-primary">Tesla</p>
                <p class="truncate max-w-full font-semi-medium text-ds-text-secondary-tertiary">
                  @Tesla
                </p>
              </div>
            </div>
            <p class="text-ds-text-primary">
              You can control most of your Tesla’s features via voice commands (with the exception
              of driving-related commands) No need to use specific words or phrases—simply speak the
              way you normally would & our natural language processor will translate your request to
              an action for your car
              lly would & our natural language processor will translate your request to
              an action for your car
              for your car
              lly would & our natural language processor will translate your request to
              an action for your car
              You can control most of your Tesla’s features via voice commands (with the exception
              of driving-related commands) No need to use specific words or phrases—simply speak the
              way you normally would & our natural language processor will translate your request to
              an action for your car
              lly would & our natural language processor will translate your request to
              an action for your car
              for your car
              lly would & our natural language processor will translate your request to
              an action for your car
            </p>
          </div>
          <template #extra>
            <div class="flex gap-x-2">
              <div class="rounded-full bg-ds-button-secondary-background p-1.5">
                <inline-svg
                  src="/img/icons/new/socials/twitter.svg"
                  class="fill-ds-button-secondary-label"
                />
              </div>
              <div class="rounded-full bg-ds-button-secondary-background p-1.5">
                <inline-svg
                  src="/img/icons/new/socials/facebook.svg"
                  class="fill-ds-button-secondary-label"
                />
              </div>
              <div class="rounded-full bg-ds-button-secondary-background p-1.5">
                <inline-svg
                  src="/img/icons/new/socials/linkedin.svg"
                  class="fill-ds-button-secondary-label"
                />
              </div>
              <div class="rounded-full bg-ds-button-secondary-background p-1.5">
                <inline-svg
                  src="/img/icons/new/socials/instagram.svg"
                  class="fill-ds-button-secondary-label"
                />
              </div>
            </div>
          </template>
        </tweet-variation-card>
      </div>
    </div>
  </div>
</template>
<script>
  import TweetVariationCard from '../Cards/DashboardCards/TweetVariationCard.vue';
  import DashboardBaseCard from '../Cards/DashboardBaseCard.vue';
  import VueSlider from 'vue-slider-component';
  export default {
    name: 'TrendingTweet',

    components: {
      TweetVariationCard,
      DashboardBaseCard,
      VueSlider,
    },
    data() {
      return {
        promoteTweetThreshold: 50,
        DotOption: {
          focusStyle: { borderColor: 'white', boxShadow: '1px 1px 10px rgb(0, 0, 0, 0.7)' },
        },
      };
    },
  };
</script>
<style lang="scss" scoped>
  h2.dashboard-title text-ds-text-primary {
    @apply text-xl font-bold;
  }
</style>
