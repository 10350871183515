<template>
  <dashboard-base-card
    class="md:rounded rounded-tl rounded-tr flex flex-col items-start justify-between gap-y-1 rounded-bl-none rounded-br-none border-ds-button-secondary-background px-4 py-2 text-base text-ds-text-primary md:flex-row md:items-center"
  >
    <slot></slot>
    <div v-if="$slots.action" class="flex items-center gap-x-4 pl-6 md:gap-1 md:pl-0">
      <slot name="action"></slot>
    </div>
  </dashboard-base-card>
</template>
<script>
  import DashboardBaseCard from '../DashboardBaseCard.vue';
  export default {
    name: 'NotificationCard',

    components: {
      DashboardBaseCard,
    },
  };
</script>
