<template>
  <div class="mb-5 grid grid-cols-1 py-2 md:mb-6 md:grid-flow-row 2xl:mx-64">
    <div class="row-start-1 flex items-center gap-x-2">
      <inline-svg src="/img/icons/new/new-video.svg" class="stroke-ds-button-icon" />
      <h2 class="dashboard-title text-ds-text-primary">New Video Content</h2>
    </div>
    <new-button size="sm" type="secondary" inactive class="row-start-3 md:row-start-1 md:ml-auto">
      <inline-svg src="/img/icons/ai-request.svg" class="mr-1 stroke-ds-button-primary-hover" />
      <span class="text-ds-button-primary-hover">Regenerate</span>
    </new-button>
    <div
      class="horizontal-scrollable-container row-start-2 mt-5 flex items-center gap-x-4 overflow-x-auto pb-16 pt-2 md:col-span-2 md:mt-6"
    >
      <video-card v-for="index in 12" :key="index">
        <div class="w-64 rounded-lg bg-gray-100 p-2" style="height: 400px">
          <div class="flex items-start justify-between">
            <div>
              <inline-svg src="/img/icons/socials/shorts-icon.svg" />
            </div>
            <div
              class="flex items-center gap-2 rounded-2xl bg-ds-background px-2 py-1 text-ds-text-primary"
            >
              <div class="h-2 w-2 rounded-full bg-green-500 text-xs"></div>
              Motivational
            </div>
          </div>
        </div>
        <template #action>
          <div class="flex w-full items-center justify-between py-2">
            <new-button size="sm">Approve</new-button>
            <div class="flex">
              <new-button size="sm" type="transparent" icon>
                <inline-svg src="/img/icons/write-icon.svg" class="h-5 w-5 stroke-main-color-100"
              /></new-button>
              <new-button size="sm" type="transparent" icon>
                <inline-svg src="/img/icons/trash-icon.svg" class="fill-main-color-100"
              /></new-button>
            </div>
          </div>
        </template>
      </video-card>
    </div>
  </div>
</template>
<script>
  import VideoCard from '../Cards/DashboardCards/VideoCard.vue';
  export default {
    name: 'NewVideoContent',

    components: {
      VideoCard,
    },
  };
</script>
<style lang="scss" scoped>
  h2.dashboard-title text-ds-text-primary {
    @apply text-xl font-bold;
  }
</style>
