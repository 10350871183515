<template>
  <div class="mb-5 grid grid-cols-1 py-2 md:mb-6 md:grid-flow-row 2xl:mx-64">
    <div class="row-start-1 flex items-center gap-x-2">
      <inline-svg src="/img/icons/new/star.svg" class="stroke-ds-button-icon" />
      <h2 class="dashboard-title text-ds-text-primary">Evergreen Content</h2>
    </div>
    <new-button size="sm" type="secondary" inactive class="row-start-3 md:row-start-1 md:ml-auto">
      <inline-svg src="/img/icons/ai-request.svg" class="mr-1 stroke-ds-button-primary-hover" />
      <span class="text-ds-button-primary-hover"
        ><span class="text-ds-button-primary-hover">Regenerate</span></span
      ></new-button
    >
    <div
      class="horizontal-scrollable-container mt-5 flex gap-x-4 overflow-x-auto pb-16 md:col-span-2 md:mt-6"
    >
      <tweet-variation-card v-for="index in 12" :key="index" class="flex-none bg-ds-background">
        <div class="mb-2 flex items-center gap-x-2 gap-y-2">
          <img
            src="https://pbs.twimg.com/profile_images/1337607516008501250/6Ggc4S5n_400x400.png"
            class="avatar-img-sm"
          />
          <div class="gap-y flex min-w-0 flex-wrap gap-x-1 text-base">
            <p class="truncate text-ellipsis max-w-full font-bold text-primary">Tesla</p>
            <p class="truncate max-w-full font-semi-medium text-ds-text-secondary-tertiary">
              @Tesla
            </p>
          </div>
        </div>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellat asperiores dolorum
          quasi accusantium nulla ipsum? Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Earum ipsum recusandae voluptates soluta repudiandae ducimus sint cupiditate dignissimos,
          aliquam rerum.
        </p>
        <template #action>
          <div class="flex w-full items-center justify-between py-2">
            <new-button size="sm">Repost</new-button>
            <div class="flex">
              <new-button size="sm" type="transparent" icon>
                <inline-svg src="/img/icons/write-icon.svg" class="h-5 w-5 stroke-main-color-100"
              /></new-button>
              <new-button size="sm" type="transparent" icon>
                <inline-svg src="/img/icons/trash-icon.svg" class="fill-main-color-100"
              /></new-button>
            </div>
          </div>
        </template>
      </tweet-variation-card>
    </div>
  </div>
</template>
<script>
  import TweetVariationCard from '../Cards/DashboardCards/TweetVariationCard.vue';
  export default {
    name: 'EvergreenContent',

    components: {
      TweetVariationCard,
    },
  };
</script>
<style lang="scss" scoped>
  h2.dashboard-title text-ds-text-primary {
    @apply text-xl font-bold;
  }
</style>
