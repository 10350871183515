<template>
  <transition-group
    enter-active-class="transform transition duration-500 ease-in-out"
    enter-class="opacity-0"
    enter-to-class="translate-y-0 opacity-100"
    leave-active-class="transition transform duration-500 ease-in"
    leave-class="translate-y-0 opacity-100"
    leave-to-class="translate-y-full opacity-0"
    tag="div"
    class="relative 2xl:mx-64"
  >
    <notification-card
      v-for="(item, index) in notificationArray"
      :key="index"
      class="absolute w-full"
      :class="{
        // 'inset-0': index === 0,
        'border -top-1 scale-95 transform border-ds-button-secondary-background': index === 2,
        'border  -top-2 scale-90 transform border-ds-button-secondary-background': index === 1,
        'border-ds-button-secondary-background bg-ds-button-secondary-background':
          index !== 1 && index !== 2,
      }"
    >
      <div class="flex gap-1">
        <inline-svg src="/img/icons/speakerphone.svg" class="bg-blue-800 w-4" />
        <p>
          Got a Facebook page or a group? Connect it so you can share your tweet on Facebook too!
        </p>
      </div>
      <template #action>
        <new-button size="xs">Setup</new-button>
        <div
          class="border cursor-pointer rounded-full border-main-color-100 p-0.5"
          @click="dismissNotification"
        >
          <new-button type="custom" size="sm" icon class="text-main-color-100">
            <inline-svg src="/img/icons/x-icon.svg" />
          </new-button>
        </div>
      </template>
    </notification-card>
  </transition-group>
</template>
<script>
  import NotificationCard from '../Cards/DashboardCards/NotificationCard.vue';
  export default {
    name: 'NotificationGroup',

    components: {
      NotificationCard,
    },
    data() {
      return {
        notificationArray: [1, 2, 3, 4, 5],
      };
    },
    methods: {
      dismissNotification() {
        const notification = this.notificationArray.shift();
        setTimeout(() => {
          this.notificationArray.push(notification);
        }, 250);
      },
    },
  };
</script>
