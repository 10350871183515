<template>
  <div>
    <dashboard-base-card :hasBorder="false" class="new-dashboard-instagram-card">
      <slot></slot>
    </dashboard-base-card>
    <div v-if="$slots.action">
      <slot name="action"> </slot>
    </div>
  </div>
</template>
<script>
  import DashboardBaseCard from '../DashboardBaseCard.vue';
  export default {
    name: 'InstagramCard',

    components: {
      DashboardBaseCard,
    },
  };
</script>
<style lang="scss" scoped>
  .new-dashboard-instagram-card {
    width: 288px;
    height: 288px;
  }
</style>
