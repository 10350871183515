<template>
  <div class="w-full">
    <div class="grid grid-cols-2 justify-between gap-5 md:grid-cols-3 2xl:mx-64">
      <metric-card class="col-span-2 md:col-span-1" :compact="true">
        <template #header>
          <div>
            <h4 class="text-lg font-medium">Schedule</h4>
            <p class="text-ds-text-secondary">Next 15 Days</p>
          </div>
          <div class="py flex items-center gap-1 rounded-full bg-gray-100 px-2 text-white">Ok</div>
        </template>
        <div class="flex flex-col gap-1">
          <div class="flex gap-1">
            <div class="h-6 w-4 bg-green-600"></div>
            <div class="h-6 w-4 bg-green-600"></div>
            <div class="h-6 w-4 bg-green-600"></div>
            <div class="h-6 w-4 bg-green-600"></div>
            <div class="h-6 w-4 bg-green-600"></div>
            <div class="h-6 w-4 bg-orange-500"></div>
            <div class="h-6 w-4 bg-orange-500"></div>
          </div>
        </div>
      </metric-card>
      <metric-card>
        <template #header>
          <div>
            <h4 class="text-lg font-medium">Enagement</h4>
            <p class="text-ds-text-secondary">Last 7 days</p>
          </div>
          <div class="py flex items-center gap-1 rounded-full bg-danger-100 px-2 text-white">
            <arrow-icon color="white" width="12" height="12" class="rotate-180 transform" />
            16%
          </div>
        </template>
        <div class="flex flex-col gap-1">
          <div class="flex gap-1">
            <div class="h-6 w-4 bg-green-600"></div>
            <div class="h-6 w-4 bg-green-600"></div>
            <div class="h-6 w-4 bg-green-600"></div>
            <div class="h-6 w-4 bg-green-600"></div>
            <div class="h-6 w-4 bg-green-600"></div>
            <div class="h-6 w-4 bg-orange-500"></div>
            <div class="h-6 w-4 bg-orange-500"></div>
          </div>
        </div>
      </metric-card>
      <metric-card>
        <template #header>
          <div>
            <h4 class="text-lg font-medium">Growth</h4>
            <p class="text-ds-text-secondary">Last 7 days</p>
          </div>
          <div class="py flex items-center gap-1 rounded-full bg-success-100 px-2 text-white">
            <arrow-icon color="white" width="12" height="12" />
            24%
          </div>
        </template>
        <div class="flex flex-col gap-1">
          <div class="flex gap-1">
            <div class="h-6 w-4 bg-green-600"></div>
            <div class="h-6 w-4 bg-green-600"></div>
            <div class="h-6 w-4 bg-green-600"></div>
            <div class="h-6 w-4 bg-green-600"></div>
            <div class="h-6 w-4 bg-green-600"></div>
            <div class="h-6 w-4 bg-orange-500"></div>
            <div class="h-6 w-4 bg-orange-500"></div>
          </div>
        </div>
      </metric-card>
    </div>
  </div>
</template>
<script>
  import MetricCard from '../Cards/DashboardCards/MetricCard.vue';
  import ArrowIcon from '../Icons/ArrowIcon.vue';
  export default {
    name: 'Metrics',

    components: {
      MetricCard,
      ArrowIcon,
    },
  };
</script>
