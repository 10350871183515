<template>
  <div
    class="new-dashboard-video-card"
    :class="{
      large: size === 'lg',
      medium: size === 'md',
    }"
  >
    <dashboard-base-card :hasBorder="false" class="h-full">
      <slot></slot>
    </dashboard-base-card>
    <div v-if="$slots.action">
      <slot name="action"> </slot>
    </div>
  </div>
</template>
<script>
  import DashboardBaseCard from '../DashboardBaseCard.vue';
  export default {
    name: 'VideoCard',

    components: {
      DashboardBaseCard,
    },
    props: {
      size: {
        type: String,
        default: 'md',
        validator: (value) => ['md', 'lg'].includes(value),
      },
    },
  };
</script>
<style lang="scss" scoped>
  .new-dashboard-video-card {
    &.large {
      max-width: 100%;
      height: 100%;
    }
    &.medium {
      width: 235.5px;
      height: 418px;
    }
  }
</style>
