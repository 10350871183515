<template>
  <dashboard-base-card class="flex w-full flex-col gap-3 p-6">
    <div
      class="flex items-start justify-between md:flex-row"
      :class="{ 'flex-row': compact, 'flex-col': !compact }"
    >
      <slot name="header"></slot>
    </div>
    <div class="text-sm text-gray-30">
      <slot></slot>
    </div>
  </dashboard-base-card>
</template>
<script>
  import DashboardBaseCard from '../DashboardBaseCard.vue';
  export default {
    name: 'MetricCard',

    components: {
      DashboardBaseCard,
    },
    props: {
      compact: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>
