<template>
  <div class="2xl:mx-64" data-cy="ama-tweet-block">
    <div class="mb-4 flex items-center justify-between py-2">
      <div class="flex items-center gap-x-2">
        <inline-svg src="/img/icons/ai-actions.svg" class="stroke-ds-button-icon" />
        <h2 class="dashboard-title text-ds-text-primary">Ask Me Anything</h2>
      </div>
      <new-button
        size="sm"
        type="secondary"
        inactive
        @click="generateAMATweet"
        :disabled="isGeneratingAMATweet || isSubmittingThread"
        v-if="amaStep === 'ama-create'"
        data-cy="ama-regenerate-button"
      >
        <inline-svg src="/img/icons/ai-request.svg" class="mr-1 stroke-ds-button-primary-hover" />
        <span class="text-ds-button-primary-hover"
          ><span class="text-ds-button-primary-hover">Regenerate</span></span
        ></new-button
      >
    </div>
    <div class="flex flex-col md:flex-row" v-if="amaStep === 'ama-create'">
      <div
        class="border relative w-full rounded-lg border-ds-outline-primary bg-ds-background pb-5 md:w-1/3 md:pb-16"
      >
        <tweet-variation-card class="relative h-320" size="lg" :hasBorder="false">
          <div class="flex h-full flex-col gap-5" v-if="!isGeneratingAMATweet">
            <div class="card-avatar-title mb-2 flex items-center gap-x-2 gap-y-2">
              <img :src="userProfile.photoURL" class="avatar-img-sm" alt="avatar" />
              <div class="gap-y flex min-w-0 flex-wrap gap-x-1 text-base">
                <p class="truncate text-ellipsis max-w-full font-bold text-primary">
                  {{ userProfile.name }}
                </p>
                <p class="truncate max-w-full font-semi-medium text-ds-text-secondary-tertiary">
                  @{{ userProfile.username }}
                </p>
              </div>
            </div>
            <p
              class="max-w-full whitespace-pre-wrap break-all text-sm text-ds-text-primary"
              v-html="formatTweet(amaTweet)"
              dir="auto"
              data-cy="ama-tweet"
            />
            <div class="mt-2 flex w-full items-center justify-between">
              <new-button
                size="sm"
                type="transparent"
                icon
                @click="editAMATweet"
                data-cy="ama-edit-button"
              >
                <inline-svg src="/img/icons/write-icon.svg" class="h-5 w-5 stroke-main-color-100" />
              </new-button>
              <new-button
                size="sm"
                @click="approveAMATweet"
                :disabled="isSubmittingThread"
                data-cy="ama-approve-button"
                >Approve</new-button
              >
            </div>
          </div>
          <div
            class="loader absolute inset-0 rounded-lg bg-ds-background"
            style="z-index: 1000"
            v-else
          >
            <loading-scheduler size="sm" />
          </div>
        </tweet-variation-card>
      </div>
      <div class="-mt-2 w-full px-3 md:my-2 md:-ml-5 md:w-2/3 md:px-0">
        <dashboard-base-card
          class="border flex h-full flex-col items-center justify-center space-y-10 rounded-lg border-gray-60 px-10 py-16 md:px-40"
        >
          <h2 class="text-center text-md">Start #AMA session to increase engagement</h2>
          <p class="text-center">
            AMAs can be a great way to interact with your audience. It allows for direct
            communication and fosters a sense of community.
          </p>
        </dashboard-base-card>
      </div>
    </div>
    <div class="flex flex-col md:flex-row" v-else-if="amaStep === 'ama-scheduled'">
      <div class="relative w-full md:w-1/3">
        <tweet-variation-card class="relative h-full bg-ds-background md:-ml-2" size="lg">
          <div class="flex h-full flex-col gap-5">
            <div class="card-avatar-title mb-2 flex items-center gap-x-2 gap-y-2">
              <img :src="userProfile.photoURL" class="avatar-img-sm" alt="avatar" />
              <div class="gap-y flex min-w-0 flex-wrap gap-x-1 text-base">
                <p class="truncate text-ellipsis max-w-full font-bold text-primary">
                  {{ userProfile.name }}
                </p>
                <p class="truncate max-w-full font-semi-medium text-ds-text-secondary-tertiary">
                  @{{ userProfile.username }}
                </p>
              </div>
            </div>
            <p
              class="max-w-full whitespace-pre-wrap break-all text-sm text-ds-text-primary"
              v-html="formatTweet(postTweet.status)"
              dir="auto"
              data-cy="ama-tweet"
            />
          </div>
        </tweet-variation-card>
      </div>
      <div class="-mt-2 w-full px-3 md:my-2 md:w-2/3 md:px-0">
        <dashboard-base-card
          class="border flex h-full flex-col items-center justify-center space-y-10 rounded-lg border-gray-60 px-10 py-16 md:px-40"
        >
          <h2 class="text-center text-md">#AMA session is scheduled on {{ postTime }}</h2>
          <p class="text-center">
            AMAs can be a great way to interact with your audience. It allows for direct
            communication and fosters a sense of community.
          </p>
        </dashboard-base-card>
      </div>
    </div>
    <div class="flex flex-col md:flex-row" v-else-if="amaStep === 'ama-posted'">
      <div class="relative w-full md:w-1/3">
        <tweet-variation-card class="relative h-full bg-ds-background md:-ml-2" size="lg">
          <div class="flex h-full flex-col gap-5">
            <div class="card-avatar-title mb-2 flex items-center gap-x-2 gap-y-2">
              <img :src="userProfile.photoURL" class="avatar-img-sm" alt="avatar" />
              <div class="gap-y flex min-w-0 flex-wrap gap-x-1 text-base">
                <p class="truncate text-ellipsis max-w-full font-bold text-primary">
                  {{ userProfile.name }}
                </p>
                <p class="truncate max-w-full font-semi-medium text-ds-text-secondary-tertiary">
                  @{{ userProfile.username }}
                </p>
              </div>
            </div>
            <p
              class="max-w-full whitespace-pre-wrap break-all text-sm text-ds-text-primary"
              v-html="formatTweet(postTweet.status)"
              dir="auto"
              data-cy="ama-tweet"
            />
          </div>
        </tweet-variation-card>
      </div>
      <div class="-mt-2 w-full px-3 md:my-2 md:w-2/3 md:px-0">
        <dashboard-base-card
          class="border flex h-full flex-col items-center justify-center space-y-10 rounded-lg border-gray-60 px-10 py-16 md:px-40"
          v-if="amaReplies.length === 0"
        >
          <h2 class="text-center text-md">
            You don't have any
            {{ amaPostData.ama.numberOfRepliesAnswered > 0 ? 'new' : '' }} replies yet.
          </h2>
          <p class="text-center">
            Patience is key! Your AMA is live, so sit tight and give the community some time to
            respond. Answers will start rolling in soon.
          </p>
        </dashboard-base-card>
        <div class="horizontal-scrollable-container flex h-full gap-x-8 overflow-x-auto pb-16 pt-8">
          <tweet-variation-card
            v-for="(amaReply, index) in amaReplies"
            :key="`amaReply-${index}`"
            v-show="amaReply.show"
            class="h-full w-64 flex-none bg-ds-background"
            size="xl"
            data-cy="ama-reply-block"
          >
            <new-button
              icon
              type="transparent"
              class="ml-auto"
              @click="queueAMAReplyForDeletion(index)"
              data-cy="ama-skip-reply"
            >
              <inline-svg src="/img/icons/new/x.svg" class="inline text-gray-80" />
            </new-button>
            <div class="mb-2 flex items-center gap-x-2 gap-y-2">
              <img :src="amaReply.userInfo.profileURL" class="avatar-img-sm" alt="avatar" />
              <div class="gap-y flex min-w-0 flex-wrap gap-x-1 text-base">
                <p class="truncate text-ellipsis max-w-full font-bold text-primary">
                  {{ amaReply.userInfo.name }}
                </p>
                <p class="truncate max-w-full font-semi-medium text-ds-text-secondary-tertiary">
                  @{{ amaReply.userInfo.username }}
                </p>
              </div>
            </div>
            <p
              class="max-w-full whitespace-pre-wrap break-all text-sm text-ds-text-primary"
              v-html="formatTweet(amaReply.tweet.status)"
              dir="auto"
            />
            <div class="input-group-alternative textarea-container mt-4">
              <textarea
                @focus="showActions"
                v-model="repliesContent[index]"
                rows="5"
                maxlength="280"
                placeholder="Quick reply here"
                class="form-control simple"
                :disabled="isSubmittingReply"
                data-cy="ama-reply-input"
                dir="auto"
              ></textarea>
              <div class="textarea-actions hidden items-center justify-between px-4 pb-2">
                <div class="flex items-center gap-x-1">
                  <new-base-checkbox
                    name="reply_quote"
                    id="reply_quote"
                    :disabled="isSubmittingReply"
                    v-model="amaReply.isQuote"
                    :inputValue="true"
                    label="Reply as a quote"
                    data-cy="ama-reply-quote"
                  />
                </div>
                <new-button
                  @click="replyToTweet(index)"
                  :disabled="isSubmittingReply || !repliesContent[index]"
                  size="sm"
                  data-cy="ama-reply-post-now"
                  >Post now</new-button
                >
              </div>
            </div>
          </tweet-variation-card>
        </div>
      </div>
    </div>
    <div class="flex flex-col md:flex-row" v-else-if="amaStep === 'create-new-ama'">
      <div class="relative w-full md:w-1/3">
        <tweet-variation-card class="relative h-full bg-ds-background md:-ml-2" size="lg">
          <div class="flex h-full flex-col gap-5">
            <div class="card-avatar-title mb-2 flex items-center gap-x-2 gap-y-2">
              <img :src="userProfile.photoURL" class="avatar-img-sm" alt="user profile picture" />
              <div class="gap-y flex min-w-0 flex-wrap gap-x-1 text-base">
                <p class="truncate text-ellipsis max-w-full font-bold text-primary">
                  {{ userProfile.name }}
                </p>
                <p class="truncate max-w-full font-semi-medium text-ds-text-secondary-tertiary">
                  @{{ userProfile.username }}
                </p>
              </div>
            </div>
            <p
              class="max-w-full whitespace-pre-wrap break-all text-sm text-ds-text-primary"
              v-html="formatTweet(postTweet.status)"
              dir="auto"
            />
          </div>
        </tweet-variation-card>
      </div>
      <div class="-mt-2 w-full px-3 md:my-2 md:w-2/3 md:px-0">
        <dashboard-base-card
          class="border flex h-full flex-col items-center justify-center space-y-10 rounded-lg border-gray-60 px-10 py-16 md:px-40"
        >
          <h2 class="text-center text-md">
            Congratulations on addressing {{ numberOfRepliesAnsweredMessage }}!
          </h2>
          <p class="text-center">
            Mission accomplished! You've tackled every question head-on. Keep an eye out for any
            follow-ups, and feel free to keep the conversation going.
          </p>
          <new-button size="sm" @click="startNewAMASession">
            <inline-svg src="/img/icons/ai-actions.svg" class="mr-2 stroke-white" />
            Start new #AMA session
          </new-button>
        </dashboard-base-card>
      </div>
    </div>
  </div>
</template>
<script>
  import TweetVariationCard from '../Cards/DashboardCards/TweetVariationCard.vue';
  import DashboardBaseCard from '../Cards/DashboardBaseCard.vue';
  import { mapGetters, mapState } from 'vuex';
  import controller from '@/controller';
  import dao from '@/dao';
  import { Thread } from '@/models/Thread';
  import { EventBus } from '@/event-bus';
  import moment from 'moment';
  import ThreadMixin from '@/views/Mixins/ThreadMixin';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import LoadingScheduler from '@/components/Loading/LoadingScheduler.vue';
  import { countTweetLength } from '@/../functions/src/util/countTweetLength';
  import lodash from 'lodash';
  import Vue from 'vue';
  import { formatTweet } from '@/util/formatTweet';

  export default {
    name: 'AmaTweet',

    components: {
      TweetVariationCard,
      DashboardBaseCard,
      LoadingScheduler,
    },
    data() {
      return {
        amaTweet: '',
        isGeneratingAMATweet: false,
        isSubmittingReply: false,
        amaStep: null,
        amaPostData: null,
        amaReplies: [],
        amaRepliesToDelete: [],
        amaRepliesDeletionTimeout: null,
        repliesContent: [],
        isQuote: false,
        isSubmittingThread: false,
        deletedAMARepliesIndexes: [],
      };
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      ...mapState(['schedule']),
      numberOfRepliesAnsweredMessage() {
        const numberOfRepliesAnswered = lodash.get(
          this.amaPostData,
          'ama.numberOfRepliesAnswered',
          null
        );
        if (numberOfRepliesAnswered === null) {
          return null;
        } else if (numberOfRepliesAnswered === 1) {
          return '1 reply';
        } else {
          return `all ${numberOfRepliesAnswered} replies`;
        }
      },
      postTweet() {
        if (this.amaPostData) {
          return this.amaPostData.tweets[0];
        }
        return null;
      },
      postTime() {
        if (this.amaPostData) {
          const scheduleTime = moment(this.amaPostData.time.toDate());
          return scheduleTime.format('MMMM Do YYYY, h:mm A');
        }
        return null;
      },
    },
    async created() {
      try {
        const queryResult = await dao.posts.fetchLastAMAPost(this.userProfile.uid);
        if (queryResult.size !== 0) {
          const amaPostDoc = queryResult.docs[0];
          this.amaPostData = amaPostDoc.data();
          if (!this.amaPostData.ama.isEnabled) {
            this.amaStep = 'create-new-ama';
            return;
          }
          if (this.amaPostData.scheduled) {
            this.amaStep = 'ama-posted';
            const amaRepliesResult = await dao.amaReplies.fetchAMAReplies(amaPostDoc.id);
            this.amaReplies = amaRepliesResult.docs.map((doc) => ({
              id: doc.id,
              isQuote: false,
              ...doc.data(),
              show: true,
            }));
          } else {
            this.amaStep = 'ama-scheduled';
          }
        } else {
          this.amaStep = 'ama-create';
          await this.generateAMATweet();
        }
      } catch {
        this.swalModal({
          title: 'Error',
          text: 'Sorry, an error has occurred while trying to fetch AMA data. Please try again.',
          type: 'error',
        });
      }
    },
    watch: {
      isSubmittingReply(v) {
        this.$emit('change-loader-state', v);
      },
      isSubmittingThread(v) {
        this.$emit('change-loader-state', v);
      },
    },
    methods: {
      formatTweet,
      deleteQueuedAMARepliesAfterDelay: lodash.debounce(async function () {
        const amaRepliesIds = this.amaRepliesToDelete.map((index) => this.amaReplies[index].id);
        try {
          await dao.amaReplies.deleteAMAReplies(amaRepliesIds);
          this.amaRepliesToDelete = [];
        } catch {
          this.swalModal({
            title: 'Error',
            text: 'Sorry, an error has occurred while trying to delete AMA reply.',
            type: 'error',
          });
        }
      }, 6000),
      queueAMAReplyForDeletion(index) {
        this.amaRepliesToDelete.push(index);
        this.amaReplies[index].show = false;
        this.deleteQueuedAMARepliesAfterDelay();
        const self = this;
        this.$notify({
          showIcon: false,
          type: 'info',
          component: Vue.component('CustomNotification', {
            render(h) {
              return h(
                'div',
                {
                  class: 'flex items-start gap-x-4 text-ds-text-primary',
                },
                [
                  h('inline-svg', {
                    props: {
                      src: '/img/icons/new/hide-question.svg',
                    },
                    class: 'h-5 w-5',
                  }),
                  h('div', { class: 'flex flex-col gap-y-2' }, [
                    h(
                      'p',
                      { class: 'text-sm text-ds-text-primary' },
                      'we won’t show this question again'
                    ),
                    h(
                      'new-button',
                      {
                        props: {
                          size: 'sm',
                          type: 'transparent',
                          icon: true,
                        },
                        on: {
                          click: self.undoAMAReplyDeletion,
                        },
                      },
                      [
                        h(
                          'span',
                          {
                            class: 'text-sm text-ds-button-icon',
                          },
                          'Undo'
                        ),
                      ]
                    ),
                  ]),
                ]
              );
            },
          }),
        });
      },
      undoAMAReplyDeletion() {
        const lastDeletedIndex = this.amaRepliesToDelete.pop();
        this.amaReplies[lastDeletedIndex].show = true;
        if (this.amaRepliesToDelete.length > 0) {
          this.deleteQueuedAMARepliesAfterDelay();
        }
      },
      startNewAMASession() {
        this.amaStep = 'ama-create';
        this.generateAMATweet();
      },
      async approveAMATweet() {
        try {
          this.isSubmittingThread = true;
          const formattedTweet = {
            ...this.emptyTweet(0),
            status: this.amaTweet,
          };
          const thread = Thread.newThread({
            time: this.schedule.getNextTimeSlot(),
            tweets: [formattedTweet],
            user: this.userProfile.uid,
            ama: { isEnabled: true, numberOfRepliesAnswered: 0 },
          });
          const result = await thread.saveToFirestore(
            this.currentUser,
            this.userProfile.uid,
            this.timezone,
            this.$eventStore
          );
          thread.id = result.id;
          this.amaPostData = thread;
          this.amaStep = 'ama-scheduled';
          this.$notify({ type: 'success', message: 'AMA tweet scheduled successfully!' });
        } catch {
          this.swalModal({
            title: 'Error',
            text: 'Sorry, an error has occurred while trying to schedule AMA tweet. Please try again.',
            type: 'error',
          });
        } finally {
          this.isSubmittingThread = false;
        }
      },
      editAMATweet() {
        const formattedTweet = {
          ...this.emptyTweet(0),
          status: this.amaTweet,
        };

        EventBus.$emit('open-new-composer', {
          tweetsFromParent: [formattedTweet],
          isAMA: true,
        });
      },
      async generateAMATweet() {
        // this.isGeneratingAMATweet = true;
        const randomAmaTweets = [
          'I’m hosting an AMA session soon. Looking forward to answering your questions and sharing my perspective on various topics. Let’s have a meaningful conversation—ask away',
          'I’m excited to open the floor for your questions in my upcoming AMA. Looking forward to diving into thoughtful discussions and sharing my experiences. Feel free to ask anything.',
          'I’ll be hosting an AMA session soon and I’m ready to answer your questions. Looking forward to engaging in insightful conversations and sharing my views. Ask away!',
          'I’m preparing for an AMA where I’ll be answering your questions. Looking forward to exchanging ideas, insights, and experiences. Let’s connect—ask your questions!',
          'Join me soon for an AMA session where I’ll be addressing your questions and sharing thoughts on different subjects. Looking forward to an engaging and insightful conversation.',
          'I’m getting ready to host an AMA session. I’m eager to answer your questions and share my thoughts on various topics. Let’s have an open and meaningful discussion—ask away.',
          'Hosting an AMA soon, and I’d love to hear from you. I’ll be answering your questions and discussing different topics. Looking forward to a thoughtful exchange—ask anything.',
          'I’ll be hosting an AMA session soon to answer your questions and share insights. I look forward to engaging with you and discussing a wide range of topics. Feel free to ask away.',
          'I’m excited to host an AMA session where I’ll be answering your questions and sharing my thoughts. Looking forward to having a thoughtful and open conversation—ask away!',
          'Looking forward to an AMA session soon. I’ll be answering your questions and sharing my experiences. Let’s have a meaningful and engaging conversation—ask your questions.',
        ];

        const randomIndex = Math.floor(Math.random() * randomAmaTweets.length);

        this.amaTweet = randomAmaTweets[randomIndex];
        // this.isGeneratingAMATweet = true;
        // try {
        //   const {
        //     data: { tweet },
        //   } = await controller.generateAMATweet(this.currentUser, this.userProfile.uid);
        //   if (countTweetLength(tweet) > 280) {
        //     // Just in case the generated tweet is too long.
        //     this.swalModal({
        //       title: 'Error',
        //       text: 'Sorry, an error has occurred while trying to generate AMA tweet. Please try again.',
        //       type: 'error',
        //     });
        //   }
        //   this.amaTweet = tweet;
        // } catch {
        //   this.swalModal({
        //     title: 'Error',
        //     text: 'Sorry, an error has occurred while trying to generate AMA tweet. Please try again.',
        //     type: 'error',
        //   });
        // } finally {
        //   this.isGeneratingAMATweet = false;
        // }
      },
      showActions(event) {
        event.target.nextElementSibling.style.display = 'flex';
      },
      async replyToTweet(index) {
        this.isSubmittingReply = true;
        const amaReply = this.amaReplies[index];
        try {
          const data = {
            tweetId: amaReply.tweet.id,
            replyText: this.repliesContent[index],
            isQuote: amaReply.isQuote,
            amaThreadId: amaReply.amaThreadRef.id,
            amaReplyId: amaReply.id,
          };

          await controller.replyToAMAReply(this.currentUser, this.userProfile.uid, data);
          this.amaReplies.splice(index, 1);
          this.repliesContent.splice(index, 1);
          this.$notify({ type: 'success', message: 'Reply posted successfully!' });
        } catch {
          this.swalModal({
            title: 'Error',
            text: 'Sorry, an error has occurred while trying to trying to reply to AMA reply.',
            type: 'error',
          });
        } finally {
          this.isSubmittingReply = false;
        }
      },
    },
    mixins: [ThreadMixin, SwalModalMixin],
  };
</script>
<style lang="scss" scoped>
  h2.dashboard-title text-ds-text-primary {
    @apply text-xl font-bold;
  }
</style>
