var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition-group',{staticClass:"relative 2xl:mx-64",attrs:{"enter-active-class":"transform transition duration-500 ease-in-out","enter-class":"opacity-0","enter-to-class":"translate-y-0 opacity-100","leave-active-class":"transition transform duration-500 ease-in","leave-class":"translate-y-0 opacity-100","leave-to-class":"translate-y-full opacity-0","tag":"div"}},_vm._l((_vm.notificationArray),function(item,index){return _c('notification-card',{key:index,staticClass:"absolute w-full",class:{
      // 'inset-0': index === 0,
      'border -top-1 scale-95 transform border-ds-button-secondary-background': index === 2,
      'border  -top-2 scale-90 transform border-ds-button-secondary-background': index === 1,
      'border-ds-button-secondary-background bg-ds-button-secondary-background':
        index !== 1 && index !== 2,
    },scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('new-button',{attrs:{"size":"xs"}},[_vm._v("Setup")]),_c('div',{staticClass:"border cursor-pointer rounded-full border-main-color-100 p-0.5",on:{"click":_vm.dismissNotification}},[_c('new-button',{staticClass:"text-main-color-100",attrs:{"type":"custom","size":"sm","icon":""}},[_c('inline-svg',{attrs:{"src":"/img/icons/x-icon.svg"}})],1)],1)]},proxy:true}],null,true)},[_c('div',{staticClass:"flex gap-1"},[_c('inline-svg',{staticClass:"bg-blue-800 w-4",attrs:{"src":"/img/icons/speakerphone.svg"}}),_c('p',[_vm._v(" Got a Facebook page or a group? Connect it so you can share your tweet on Facebook too! ")])],1)])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }