<template>
  <div>
    <div class="2xl:mx-64">
      <div class="flex items-center gap-x-4">
        <h1 class="text-3xl text-ds-text-primary md:mb-0 md:text-4xl">Dashboard</h1>
        <span
          class="border rounded-2xl border-ds-outline-primary px-3 py-1 text-sm text-ds-text-primary"
          >Beta</span
        >
      </div>
      <hr class="hidden md:mt-8 md:block md:w-1/2" />
    </div>
    <fade-transition
      :duration="{
        leave: 0,
      }"
      class="h-full w-full"
    >
      <div v-show="isLoading" class="loader flex items-center justify-center">
        <loading-scheduler size="lg" />
      </div>
    </fade-transition>
    <fade-transition
      :duration="{
        enter: 500,
      }"
    >
      <div class="mt-4 w-full" v-show="!isLoading">
        <div class="mx-auto flex max-w-4xl justify-center gap-y-4">
          <new-alert v-if="!isUserConnectedToInstagram">
            This feature requires Instagram connection.<br />You can create and schedule reel but it
            won't published unless you
            <router-link to="/settings#instagram" class="text-main-color-100">
              connect your Instagram account to Hypefury</router-link
            >.
          </new-alert>
          <new-alert v-else-if="!doesUserHaveInstagramSlot">
            You don't have any available Instagram slots.<br />
            <router-link to="/queue#schedule" class="text-main-color-100"
              >You can add them here
            </router-link>
            or simply schedule a reel at a custom time.
          </new-alert>
        </div>
        <metrics class="my-8" v-if="false" />

        <notification-group v-if="false" />

        <tweet-variations class="mb-8 mt-48 md:mt-32" v-if="false" />

        <new-video-content class="my-8" v-if="false" />

        <trending-tweet class="my-8" v-if="false" />

        <tweet-to-instagram class="my-8" v-if="false" />

        <tweet-to-video
          class="my-8"
          :canUserApproveReel="doesUserHaveInstagramSlot"
          :isScheduleLoaded="isWholeScheduleLoaded"
          @start-loading="isLoading = true"
          @stop-loading="isLoading = false"
        />

        <ama-tweet class="my-8" @change-loader-state="(v) => (isLoading = v)" />

        <evergreen-content class="my-8" v-if="false" />

        <youtube-to-tweet class="my-8" v-if="false" />
      </div>
    </fade-transition>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import Metrics from '../components/Dashboard/Metrics.vue';
  import NotificationGroup from '../components/Dashboard/NotificationGroup.vue';
  import TweetVariations from '../components/Dashboard/TweetVariations.vue';
  import NewVideoContent from '../components/Dashboard/NewVideoContent.vue';
  import TrendingTweet from '../components/Dashboard/TrendingTweet.vue';
  import EvergreenContent from '../components/Dashboard/EvergreenContent.vue';
  import TweetToVideo from '../components/Dashboard/TweetToVideo.vue';
  import YoutubeToTweet from '../components/Dashboard/YoutubeToTweet.vue';
  import TweetToInstagram from '../components/Dashboard/TweetToInstagram.vue';
  import AmaTweet from '../components/Dashboard/AMATweet.vue';
  import { FadeTransition } from 'vue2-transitions';
  import LoadingScheduler from '@/components/Loading/LoadingScheduler.vue';
  import InstagramMixin from '@/views/Mixins/InstagramMixin.vue';

  // import lodash from 'lodash';
  // const config = require('@/config');

  export default {
    // beforeRouteEnter(to, from, next) {
    //   next((vm) => {
    //     const isDashboardPreviewOn = lodash.get(vm.$route, 'query.preview', '') === 'on';
    //     if (config.isEnvProd && !isDashboardPreviewOn) {
    //       vm.$router.push('/queue');
    //     }
    //   });
    // },
    components: {
      Metrics,
      NotificationGroup,
      TweetVariations,
      NewVideoContent,
      TrendingTweet,
      EvergreenContent,
      TweetToVideo,
      YoutubeToTweet,
      TweetToInstagram,
      AmaTweet,
      FadeTransition,
      LoadingScheduler,
    },
    mixins: [InstagramMixin],
    computed: {
      ...mapState(['schedule', 'isWholeScheduleLoaded']),
      doesUserHaveInstagramSlot() {
        return (
          !this.isWholeScheduleLoaded ||
          this.schedule.getAllSlots().some((slot) => slot.slotType === 'instagram')
        );
      },
    },
    data() {
      return {
        isLoading: false,
      };
    },
  };
</script>
<style lang="scss">
  .horizontal-scrollable-container::-webkit-scrollbar-thumb {
    display: none;
  }

  .horizontal-scrollable-container {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .horizontal-scrollable-container:hover::-webkit-scrollbar-thumb {
    display: block;
  }

  .horizontal-scrollable-container:hover {
    scrollbar-width: auto;
    -ms-overflow-style: auto;
  }

  .horizontal-scrollable-container {
    -webkit-overflow-scrolling: touch;
  }
</style>
